import { Avatar, Grid, IconButton } from "@mui/material";
import LogoPNG2 from "../assets/flow_logo2.png";
import LinkedinLogo from "../assets/logos/linkedin_icon.svg";
import EmailIcon from "@mui/icons-material/Email";
import AnchorLink from "react-anchor-link-smooth-scroll";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";

const Footer = () => {
  return (
    <div>
      <div
        className="Footer"
        style={{
          // height: 1000,
          background: "#14173a",
          // background: "linear-gradient(#14173a 80%, #fff)",
          width: "100%",
        }}
      >
        <div
          style={{
            height: 1,
            background: "#ccc",
            width: "80%",
            marginTop: 40,
          }}
        ></div>
        <Grid
          container
          sx={{ paddingLeft: "15vw", paddingRight: "15vw", paddingTop: "3vw" }}
        >
          <Grid item lg={6} style={{ textAlign: "left" }}>
            <Grid item>
              <img
                src={LogoPNG2}
                alt="logo"
                width="160vw"
                style={{ imageRendering: "-webkit-optimize-contrast" }}
              />
            </Grid>
            <IconButton
              href="https://www.linkedin.com/company/proflowai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LinkedinLogo} alt="logo" width="40vw" />
            </IconButton>
            <IconButton
              href={
                "mailto:contact@proflowai.com?subject=Contact request from proflow.ai"
              }
            >
              <Avatar sx={{ width: 35, height: 35, bgcolor: "white" }}>
                <EmailIcon sx={{ color: "#14173a" }} />
              </Avatar>
            </IconButton>
            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                padding: { xs: "20px 16px 20px 0px", sm: "30px 48px 30px 0px" },
                fontSize: { xs: "0.6rem", sm: "0.8rem" },
                fontWeight: 400,
                textAlign: "left",
              }}
            >
              <p>
                Proflow AI, Pty Ltd. 2 Innovation Parkway, Birtinya, QLD, 4575,
                Australia
              </p>
              <p>
                &copy; {new Date().getFullYear()} Proflow AI, Pty Ltd. All
                Rights Reserved
              </p>
            </Grid>
          </Grid>

          <Grid item lg={2} style={{ textAlign: "left" }}>
            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                padding: {
                  xs: "20px 16px 12px 0px",
                  sm: "20px 48px 12px 0px",
                },
                fontSize: { xs: "1rem", sm: "1.4rem" },
                fontWeight: 400,
                textAlign: "left",
              }}
            >
              <b>Services</b>
            </Grid>
            <Grid
              item
              sx={{
                fontFamily: "Comfortaa",
                padding: {
                  xs: "0px 16px 40px 0px",
                  sm: "0px 48px 40px 0px",
                },
                fontSize: { xs: "0.6rem", sm: "0.8rem" },
                fontWeight: 400,
                textAlign: "left",
              }}
            >
              <p>
                <AnchorLink href="#service1" style={{ color: "#fff" }}>
                  Software development
                </AnchorLink>
              </p>
              <p>
                <AnchorLink href="#service2" style={{ color: "#fff" }}>
                  Data structuring
                </AnchorLink>
              </p>
              <p>
                <AnchorLink href="#service3" style={{ color: "#fff" }}>
                  Advanced analytics
                </AnchorLink>
              </p>
            </Grid>
          </Grid>

          <Grid item lg={2} style={{ textAlign: "left" }}>
            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                padding: {
                  xs: "20px 16px 12px 0px",
                  sm: "20px 48px 12px 0px",
                },
                fontSize: { xs: "1rem", sm: "1.4rem" },
                fontWeight: 400,
                textAlign: "left",
              }}
            >
              <b>Products</b>
            </Grid>
            <Grid
              item
              sx={{
                fontFamily: "Comfortaa",
                padding: {
                  xs: "0px 16px 40px 0px",
                  sm: "0px 48px 40px 0px",
                },
                fontSize: { xs: "0.6rem", sm: "0.8rem" },
                fontWeight: 400,
                textAlign: "left",
              }}
            >
              <p>
                <AnchorLink href="#product1" style={{ color: "#fff" }}>
                  Portfolio optimisation
                </AnchorLink>
              </p>
              <p>
                <AnchorLink href="#product2" style={{ color: "#fff" }}>
                  Valuation marketplace
                </AnchorLink>
              </p>
            </Grid>
          </Grid>

          <Grid item lg={2} style={{ textAlign: "left" }}>
            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                padding: {
                  xs: "20px 16px 12px 0px",
                  sm: "20px 48px 12px 0px",
                },
                fontSize: { xs: "1rem", sm: "1.4rem" },
                fontWeight: 400,
                textAlign: "left",
              }}
            >
              <b>Legal</b>
            </Grid>
            <Grid
              item
              sx={{
                fontFamily: "Comfortaa",
                padding: {
                  xs: "0px 16px 40px 0px",
                  sm: "0px 48px 40px 0px",
                },
                fontSize: { xs: "0.6rem", sm: "0.8rem" },
                fontWeight: 400,
                textAlign: "left",
              }}
            >
              <p>
                <TermsOfUse />
              </p>
              <p>
                <PrivacyPolicy />
              </p>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
