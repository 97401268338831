import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactGA from "react-ga";

const PrivacyPolicy = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    ReactGA.event({
      category: "LinkPrivacyPolicy",
      action: "Privacy policy link clicked",
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleClose = (event, reason) => {
  //   if (reason !== "backdropClick") {
  //     setOpen(false);
  //   }
  // };

  return (
    <>
      <div
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        style={{ cursor: "pointer" }}
      >
        Privacy policy
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Privacy policy</DialogTitle>
        <DialogContent style={{ minHeight: "50vh" }}>
          <DialogContentText>
            <p>
              <span>
                Proflow AI Pty Ltd ABN 38658195450 (<b>we</b>, <b>us</b> or
                <b> our</b>
                ), understands that protecting your personal information is
                important. This Privacy Policy sets out our commitment to
                protecting the privacy of personal information provided to us,
                or otherwise collected by us when providing our website to you (
                <b>Services</b>) or when otherwise interacting with you.
              </span>
            </p>

            <p>
              <b>
                <br />
                <span>The information we collect</span>
              </b>
            </p>

            <p>
              <b>
                <br />
                <span>Personal information</span>
              </b>
              <span>
                : is information or an opinion, whether true or not and whether
                recorded in a material form or not, about an individual who is
                identified or reasonably identifiable.
              </span>
            </p>

            <p>
              <span>
                The types of personal information we may collect about you
                include:
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>your name;</span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>your contact details, including email address;</span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>your employer and job title;</span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>your preferences and/or opinions;</span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                information you provide to us, including through feedback,
                customer surveys or otherwise;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                details of products and services we have provided to you and/or
                that you have enquired about, and our response to you;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                your browser session and geo-location data, device and network
                information, statistics on page views and sessions, acquisition
                sources, search queries and/or browsing behaviour;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                information about your access and use of our Services, including
                through the use of Internet cookies, your communications with
                our online Services, the type of browser you are using, the type
                of operating system you are using and the domain name of your
                Internet service provider;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                additional personal information that you provide to us, directly
                or indirectly, through your use of our Services, associated
                applications, associated social media platforms and/or accounts
                from which you permit us to collect information; and
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                any other personal information requested by us and/or provided
                by you or a third party.
              </span>
            </p>

            <p>
              <b>
                <br />
                <span>How we collect personal information</span>
              </b>
            </p>

            <p>
              <br />
              <span>
                We collect personal information in a variety of ways, including:
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp;</span>
                </span>
              </span>
              <b>
                <span>Directly:</span>
              </b>
              <span>
                {" "}
                We collect personal information which you directly provide to
                us, including through the 'contact us' or 'request a demo' form
                on our website, or when you request our assistance via email, or
                over the telephone.
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp;</span>
                </span>
              </span>
              <b>
                <span>Indirectly:</span>
              </b>
              <span>
                {" "}
                We may collect personal information which you indirectly provide
                to us while interacting with us, such as when you use our
                website, in emails, over the telephone and in your online
                enquiries.
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp;</span>
                </span>
              </span>
              <b>
                <span>From third parties:</span>
              </b>
              <span>
                {" "}
                We collect personal information from third parties, such as
                details of your use of our website from our analytics and cookie
                providers and marketing providers. See the 'Cookies' section
                below for more detail on the use of cookies.
              </span>
            </p>

            <p>
              <span>
                <b>
                  <br />
                  Why we collect, hold, use and disclose personal information
                </b>
              </span>
            </p>

            <p>
              <b>
                <br />
                <span>Personal information: </span>
              </b>
              <span>
                We may collect, hold, use and disclose personal information for
                the following purposes:
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>to enable you to access and use our Services;</span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                to provide our Services to you, including to organise a meeting
                with you to discuss our products and services;{" "}
              </span>
              <span></span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                to enable you to access and use our associated applications;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                to contact and communicate with you about our Services,
                including in response to any enquiries you make with us;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                for internal record keeping, administrative, invoicing and
                billing purposes;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                for analytics, market research and business development,
                including to operate and improve our Services and associated
                applications;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                for advertising and marketing, including to send you promotional
                information about our products and services and other
                information that we consider may be of interest to you;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                to comply with our legal obligations and resolve any disputes
                that we may have;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                if you have applied for employment with us; to consider your
                employment application; and
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>if otherwise required or authorised by law.</span>
            </p>

            <p>
              <span>
                <b>
                  <br />
                  Our disclosures of personal information to third parties
                </b>
              </span>
            </p>

            <p>
              <br />
              <span>We may disclose personal information to:</span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                third party service providers for the purpose of enabling them
                to provide their services, to us, including (without limitation)
                <b>
                  <span> </span>
                </b>
                IT service providers, data storage, web-hosting and server
                providers, email marketing providers, maintenance or
                problem-solving providers, marketing or advertising providers
                and professional advisors;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>our employees, contractors and/or related entities;</span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                our existing or potential agents or business partners;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                anyone to whom our business or assets (or any part of them) are,
                or may (in good faith) be, transferred;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                courts, tribunals, regulatory authorities and law enforcement
                officers, as required or authorised by law, in connection with
                any actual or prospective legal proceedings, or in order to
                establish, exercise or defend our legal rights;
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                third parties to collect and process data, such as Google
                Analytics (To find out how Google uses data when you use third
                party websites or applications, please see
                www.google.com/policies/privacy/partners/ or any other URL
                Google may use from time to time); and
              </span>
            </p>

            <p>
              <span>
                <span>
                  &nbsp;&nbsp;&nbsp;&#9702;
                  <span>&nbsp;&nbsp;&nbsp; </span>
                </span>
              </span>
              <span>
                any other third parties as required or permitted by law, such as
                where we receive a subpoena.
              </span>
            </p>

            <p>
              <b>
                <span>Google Analytics: </span>
              </b>
              <span>
                We have enabled Google Analytics Advertising Features including
                Remarketing Features, Advertising Reporting Features,
                Demographics and Interest Reports, Store Visits, Google Display
                Network Impression reporting. We and third-party vendors use
                first-party cookies (such as the Google Analytics cookie) or
                other first-party identifiers, and third-party cookies (such as
                Google advertising cookies) or other third-party identifiers
                together.
              </span>
            </p>

            <p>
              <span>
                You can opt-out of Google Analytics Advertising Features
                including using a Google Analytics Opt-out Browser add-on found{" "}
              </span>
              <a href="https://tools.google.com/dlpage/gaoptout/">
                <span>here</span>
              </a>
              <span>
                . To opt-out of personalised ad delivery on the Google content
                network, please visit Google's Ads Preferences Manager{" "}
              </span>
              <a href="http://www.google.com/ads/preferences">
                <span>here</span>
              </a>
              <span>
                {" "}
                or if you wish to opt-out permanently even when all cookies are
                deleted from your browser you can install their plugin{" "}
              </span>
              <a href="https://support.google.com/ads/answer/7395996">
                <span>here</span>
              </a>
              <span>
                . To opt out of interest-based ads on mobile devices, please
                follow these instructions for your mobile device: On android
                open the Google Settings app on your device and select 'ads' to
                control the settings. On iOS devices with iOS 6 and above use
                Apple's advertising identifier. To learn more about limiting ad
                tracking using this identifier, visit the settings menu on your
                device.
              </span>
            </p>

            <p>
              <b>
                <br />
                <span>Overseas disclosure</span>
              </b>
            </p>

            <p>
              <span>
                <br />
                We may store personal information overseas. Where we disclose
                your personal information to the third parties listed above,
                these third parties may also store, transfer or access personal
                information outside of Australia.
              </span>
            </p>

            <p>
              <span>Your rights and controlling your personal information</span>
            </p>

            <p>
              <b>
                <span>Your choice</span>
              </b>
              <span>
                : Please read this Privacy Policy carefully. If you provide
                personal information to us, you understand we will collect,
                hold, use and disclose your personal information in accordance
                with this Privacy Policy. You do not have to provide personal
                information to us, however, if you do not, it may affect our
                ability to provide our Services to you and your use of our
                Services.
              </span>
            </p>

            <p>
              <b>
                <span>Information from third parties</span>
              </b>
              <span>
                : If we receive personal information about you from a third
                party, we will protect it as set out in this Privacy Policy. If
                you are a third party providing personal information about
                somebody else, you represent and warrant that you have such
                person's consent to provide the personal information to us.{" "}
              </span>
            </p>

            <p>
              <b>
                <span>Anonymity: </span>
              </b>
              <span>
                Where practicable we will give you the option of not identifying
                yourself or using a pseudonym in your dealings with us.{" "}
              </span>
            </p>

            <p>
              <b>
                <span>Restrict and unsubscribe: </span>
              </b>
              <span>
                To object to processing for direct marketing/unsubscribe from
                our email database or opt-out of communications (including
                marketing communications), please contact us using the details
                below or opt-out using the opt-out facilities provided in the
                communication.
              </span>
            </p>

            <p>
              <b>
                <span>Access</span>
              </b>
              <span>
                : You may request access to the personal information that we
                hold about you. An administrative fee may be payable for the
                provision of such information. Please note, in some situations,
                we may be legally permitted to withhold access to your personal
                information. If we cannot provide access to your information, we
                will advise you as soon as reasonably possible and provide you
                with the reasons for our refusal and any mechanism available to
                complain about the refusal. If we can provide access to your
                information in another form that still meets your needs, then we
                will take reasonable steps to give you such access.
              </span>
            </p>

            <p>
              <b>
                <span>Correction:</span>
              </b>
              <span>
                {" "}
                If you believe that any <span>information</span> we hold about
                you is inaccurate, out of date, incomplete, irrelevant or
                misleading, please contact us using the details below. We will
                take reasonable steps to promptly correct any information found
                to be inaccurate, out of date, incomplete, irrelevant or
                misleading. Please note, in some situations, we may be legally
                permitted to not correct your personal information. If we cannot
                correct your information, we will advise you as soon as
                reasonably possible and provide you with the reasons for our
                refusal and any mechanism available to complain about the
                refusal.
              </span>
            </p>

            <p>
              <b>
                <span>Complaints</span>
              </b>
              <span>
                : If you wish to make a complaint, please contact us using the
                details below and provide us with full details of the complaint.
                We will promptly investigate your complaint and respond to you,
                in writing, setting out the outcome of our investigation and the
                steps we will take in response to your complaint.{" "}
                <b>
                  <span></span>
                </b>
              </span>
            </p>

            <p>
              <b>
                <br />
                <span>Storage and security</span>
              </b>
            </p>

            <p>
              <br />
              <span>
                We are committed to ensuring that the personal information we
                collect is secure. In order to prevent unauthorised access or
                disclosure, we have put in place suitable physical, electronic
                and managerial procedures, to safeguard and secure personal
                information and protect it from misuse, interference, loss and
                unauthorised access, modification and disclosure.
              </span>
            </p>

            <p>
              <span>
                While we are committed to security, we cannot guarantee the
                security of any information that is transmitted to or by us over
                the Internet. The transmission and exchange of information is
                carried out at your own risk.
              </span>
            </p>

            <p>
              <b>
                <br />

                <span>Cookies</span>
              </b>
            </p>

            <p>
              <br />
              <span>
                We may use cookies on our website from time to time. Cookies are
                text files placed in your computer's browser to store your
                preferences. Cookies, by themselves, do not tell us your email
                address or other personally identifiable information. However,
                they do recognise you when you return to our online website and
                allow third parties, such as Google and Facebook, to cause our
                advertisements to appear on your social media and online media
                feeds as part of our retargeting campaigns. If and when you
                choose to provide our online website with personal information,
                this information may be linked to the data stored in the cookie.
              </span>
            </p>

            <p>
              <span>
                You can block cookies by activating the setting on your browser
                that allows you to refuse the setting of all or some cookies.
                However, if you use your browser settings to block all cookies
                (including essential cookies) you may not be able to access all
                or parts of our website.
              </span>
            </p>

            <p>
              <b>
                <br />
                <span>Links to other websites</span>
              </b>
            </p>

            <p>
              <br />
              <span>
                Our website may contain links to other party's websites. We do
                not have any control over those websites and we are not
                responsible for the protection and privacy of any personal
                information which you provide whilst visiting those websites.
                Those websites are not governed by this Privacy Policy.
              </span>
            </p>

            <p>
              <b>
                <br />
                <span>Amendments</span>
              </b>
            </p>

            <p>
              <br />
              <span>
                We may, at any time and at our discretion, vary this Privacy
                Policy by publishing the amended Privacy Policy on our website.
                We recommend you check our website regularly to ensure you are
                aware of our current Privacy Policy.
              </span>
            </p>

            <p>
              <b>
                <br />
                <span>For any questions or notices, please contact us at:</span>
              </b>
            </p>

            <p>
              <br />
              <span>Proflow AI, Pty Ltd ABN 38658195450</span>
            </p>
            <p>
              <span>2 Innovation Parkway, Birtinya, QLD, 4575, Australia</span>
            </p>

            <p>
              <span>
                <a href="mailto:contact@proflowai.com">contact@proflowai.com</a>
              </span>
            </p>

            <p>
              <b>
                <br />
                <span>Last update</span>
              </b>
              <span>: 31 May 2022</span>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PrivacyPolicy;
