import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactGA from "react-ga";

const TermsOfUse = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    ReactGA.event({
      category: "LinkTermsOfUse",
      action: "Terms of use link clicked",
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleClose = (event, reason) => {
  //   if (reason !== "backdropClick") {
  //     setOpen(false);
  //   }
  // };

  return (
    <>
      <div
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        style={{ cursor: "pointer" }}
      >
        Terms of use
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Terms of use</DialogTitle>
        <DialogContent style={{ minHeight: "50vh" }}>
          <DialogContentText>
            <p>
              <span>
                This website (<b>Site</b>) is operated by Proflow AI Pty Ltd
                (ABN: 38658195450) (<b>we</b>, <b>our</b> or <b>us</b>). These
                website terms of use (<b>Terms</b>) apply to your use of, and
                access to, the Site.{" "}
              </span>
            </p>

            <p>
              <br />
              <b>Information and availability</b>
            </p>

            <p>
              <span>
                While we use reasonable attempts to ensure the accuracy and
                completeness of the content and materials on the Site (
                <b>Content</b>), to the extent permitted by law (including the
                Australian Consumer Law), we do not warrant the accuracy,
                completeness or suitability of any of the Content. The Content
                may be subject to change without notice and we do not undertake
                to keep the Site up-to-date. The Content is factual information
                only, is not comprehensive and is for general information
                purposes only. We also do not warrant that access to the Site
                will be uninterrupted, error-free or free from viruses.
              </span>
            </p>

            <p>
              <br />
              <b>Intellectual Property rights </b>
            </p>

            <p>
              <span>
                Unless otherwise indicated, we own or license the Content and
                all intellectual property rights (including any copyright,
                registered or unregistered designs, illustrations, artwork,
                patents or trade mark or logo rights and domain names) displayed
                or used on the Site (<b>Our</b> <b>Intellectual Property</b>
                ).{" "}
              </span>
            </p>

            <p>
              <span>
                We authorise you to access and use the Site solely for your own
                personal, non-commercial use and to display, print and download
                the Content onto your personal device provided that you do not
                remove any copyright notice included in Our Intellectual
                Property.{" "}
              </span>
            </p>

            <p>
              <span>
                You must not use the Site, or any of the Content, for your
                commercial purposes, including, for example, to advertise your
                own business or for any other revenue generation activity and
                you must not use the Site, including the Content, in any way
                that competes with our business.
              </span>
            </p>

            <p>
              <span>
                Subject to the above, your use of, and access to, the Site and
                the Content does not grant or transfer to you any rights, title
                or interest to Our Intellectual Property. Unless otherwise
                permitted in these Terms, you must not:{" "}
              </span>
            </p>

            <span>
              <span>
                (a)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>
              copy or use, in whole or in part, any of Our Intellectual
              Property;{" "}
            </span>

            <span>
              <span>
                <p />
                (b)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>
              reproduce, retransmit, distribute, display, disseminate, sell,
              publish, broadcast or circulate any of Our Intellectual Property;
              or
            </span>

            <span>
              <span>
                <p />
                (c)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>
              breach any intellectual property rights connected with Our
              Intellectual Property, including altering or modifying any of Our
              Intellectual Property, causing any of Our Intellectual Property to
              be framed or embedded in another website or platform, or creating
              derivative works from Our Intellectual Property.
            </span>

            <span>
              Nothing in the above clause restricts your ability to publish,
              post or repost Content or Our Intellectual Property on your social
              media page or blog, provided that:
            </span>

            <span>
              <span>
                <p />
                (d)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>
              you do not assert that you are the owner of the Content or Our
              Intellectual Property;
            </span>

            <span>
              <span>
                <p />
                (e)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>
              unless explicitly agreed by us in writing, you do not assert that
              you are endorsed or approved by us;{" "}
            </span>

            <span>
              <span>
                <p />
                (f)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>
              you do not damage or take advantage of our reputation, including
              in a manner that is illegal, unfair, misleading or deceptive; and{" "}
            </span>

            <span>
              <span>
                <p />
                (g)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>you comply with all other terms of these Terms. </span>

            <p>
              <br />
              <b>Conduct we don't accept </b>
            </p>

            <p>
              <span>
                You must not do or attempt to do anything that is unlawful,
                which is prohibited by applicable law, which we would consider
                inappropriate or which might bring us or the Site into
                disrepute. This includes:
              </span>
            </p>

            <span>
              <span>
                (a)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>
              anything that would constitute a breach of an individual's privacy
              or any other legal rights;
            </span>

            <span>
              <span>
                <p />
                (b)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>
              using the Site to defame, harass, threaten, menace or offend any
              person;
            </span>

            <span>
              <span>
                <p />
                (c)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>using the Site for unlawful purposes;</span>

            <span>
              <span>
                <p />
                (d)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>interfering with any user of the Site;</span>

            <span>
              <span>
                <p />
                (e)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>
              tampering with or modifying the Site, knowingly transmitting
              viruses or other disabling features, or damaging or interfering
              with the Site, including using trojan horses, viruses or piracy or
              programming routines that may damage or interfere with the Site;
            </span>

            <span>
              <span>
                <p />
                (f)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>
              using the Site to send unsolicited electronic messages;{" "}
            </span>

            <span>
              <span>
                <p />
                (g)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>
              using data mining, robots, screen scraping or similar data
              gathering and extraction tools on the Site; or
            </span>

            <span>
              <span>
                <p />
                (h)
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </span>
            <span>
              facilitating or assisting a third party to do any of the above
              acts.
            </span>

            <p>
              <br />
              <b>Third party sites</b>
            </p>

            <p>
              <span>
                The Site may contain links to websites operated by third
                parties. Unless we tell you otherwise, we do not control,
                endorse or approve, and are not responsible for, the content on
                those websites. We recommend that you make your own
                investigations with respect to the suitability of those
                websites. If you purchase goods or services from a{" "}
                <span>third party</span>
                website linked from the Site, such third party provides the
                goods and services to you, not us.
              </span>
            </p>

            <p>
              <br />
              <b>Our liability is limited</b>
            </p>

            <span>
              You may have certain rights under the Australian Consumer Law in
              relation to the Site and the Content, and you may contact us for
              more information about this. Despite
            </span>

            <span>
              <span>
                anything to the contrary, to the maximum extent permitted by law
                (including the Australian Consumer Law), we exclude all
                liability for any loss or damage of any kind (including
                consequential loss, indirect loss, loss of profit, loss of
                benefit, loss of opportunity or loss of reputation) w
              </span>
            </span>
            <span>
              <span>
                hether under statute, contract, equity, tort (including
                negligence), indemnity or otherwise arising out of or in
                connection with the Site or the Content.
              </span>
            </span>

            <p>
              <br />
              <b>Privacy</b>
            </p>

            <p>
              <span>
                We respect your privacy and understand protecting your personal
                information is important. Our Privacy Policy (available on the
                Site) sets out how we will collect and handle your personal
                information.<span></span>
              </span>
            </p>

            <p>
              <b>
                <br />
                What happens if we discontinue the <span>Site</span>{" "}
              </b>
            </p>

            <p>
              <span>
                We may, at any time and without notice, discontinue the Site (in
                whole or in part), or exclude any person from using our Site.
              </span>
            </p>

            <p>
              <br />
              <b>Which laws govern these Terms</b>
            </p>

            <p>
              <span>
                These Terms are governed by the laws of Queensland. Each party
                irrevocably and unconditionally submits to the exclusive
                jurisdiction of the courts operating in Queensland and any
                courts entitled to hear appeals from those courts and waives any
                right to object to proceedings being brought in those courts.
              </span>
              <span></span>
            </p>

            <p>
              <br />
              <b>Changes to these Terms</b>
            </p>

            <p>
              <span>
                We may, at any time and at our discretion, vary these Terms by
                publishing the varied terms on the Site. We recommend you check
                the Site regularly to ensure you are aware of our current terms.
              </span>
              <span> </span>
              <span></span>
            </p>

            <p>
              <b>
                <br />
                <span>For any questions or notices, please contact us at:</span>
              </b>
            </p>
            <p>
              <br />
              <span>Proflow AI, Pty Ltd ABN 38658195450</span>
            </p>
            <p>
              <span>2 Innovation Parkway, Birtinya, QLD, 4575, Australia</span>
            </p>

            <p>
              <span>
                <a href="mailto:contact@proflowai.com">contact@proflowai.com</a>
              </span>
            </p>

            <p>
              <b>
                <br />
                <span>Last update</span>
              </b>
              <span>: 31 May 2022</span>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TermsOfUse;
