import { Grid } from "@mui/material";
import AwsLogo from "../assets/logos/Amazon_Web_Services_Logo.svg";
import LVLogo from "../assets/logos/logo-lv__header.png";
import AtlassianLogo from "../assets/logos/Atlassian-logo.svg";
import BDOLogo from "../assets/logos/BDO_logo.svg";
// import GHLogo from "../assets/logos/GitHub_Logo_White.png";
import RICS from "../assets/logos/RICS_TP.png";

const Partners = () => {
  return (
    <div>
      <Grid container className="Partners">
        <Grid
          item
          xs={12}
          // className="App-subheader"
          sx={{
            fontFamily: "Comfortaa",
            fontSize: { xs: "0.7rem", sm: "1.2rem" },
            paddingTop: "20px",
            paddingBottom: "64px",
            fontWeight: 400,
            letterSpacing: "1px",
            // textAlign: "left",
          }}
        >
          We are proud to work with worldclass partners
        </Grid>
        <Grid item>
          <img
            src={RICS}
            alt="RICS Logo"
            height="100"
            style={{
              padding: "0px 50px 0px 50px",
              // imageRendering: "-webkit-optimize-contrast",
            }}
          />
          <img
            src={BDOLogo}
            alt="BDO Logo"
            height="50"
            style={{ padding: "20px 50px 30px 50px" }}
          />
          <img
            src={AtlassianLogo}
            alt="AT Logo"
            height="30"
            style={{ padding: "20px 50px 40px 50px" }}
          />
          <img
            src={AwsLogo}
            alt="AWS Logo"
            height="50"
            style={{ padding: "20px 50px 20px 50px" }}
          />
          <img
            src={LVLogo}
            alt="LV Logo"
            height="30"
            style={{ padding: "20px 50px 35px 50px" }}
          />
          {/* <img
            src={GHLogo}
            alt="GH Logo"
            height="40"
            style={{ padding: "10px 50px 30px 50px" }}
          /> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Partners;
