export const companies = [
  "Aberdeen Standard Invest",
  "abrdn",
  "Activia",
  "ADIA",
  "ADO Properties",
  "Aeon",
  "AEW",
  "Agile Property",
  "AIG",
  "AIR",
  "Alexandria",
  "Alibaba Group",
  "Allianz",
  "Alphabet Inc",
  "Amazon",
  "AMB",
  "Amerco Real Estate Co",
  "American Campus Cmnties",
  "American Realty Advisors",
  "AMP Capital",
  "Amundi",
  "Anbang Insurance",
  "APG Group",
  "Apollo Global RE",
  "ARA Asset Management",
  "Ares Management",
  "Aroundtown",
  "Art Invest",
  "Ascendas India Trust",
  "Ascendas REIT",
  "Ashkenazy Acquisition",
  "AustralianSuper",
  "AvalonBay",
  "AVIC International",
  "Aviva",
  "AXA Group",
  "Bain Capital",
  "Barings",
  "BayernLB",
  "BBMG Corporation",
  "bcIMC",
  "Beacon Capital Partners",
  "Beijing Capital Dev Holdings",
  "Beijing Capital Group",
  "Beijing Constr Engineering",
  "Beijing International Trust Co",
  "Beijing North Star",
  "Beijing Tianheng RE",
  "Beijing Urban Const Grp",
  "Beijing Zhuzong",
  "BentallGreenOak",
  "BlackRock",
  "Blackstone",
  "BMO Financial Group",
  "BNP Paribas",
  "Boston Properties",
  "BREIT",
  "Bridge Investment Grp",
  "Bright Real Estate",
  "British Land",
  "Brixmor",
  "Brookdale Senior Living",
  "Brookfield AM",
  "Brookfield Property REIT",
  "BVK",
  "CC Land Holdings",
  "CA Immo",
  "Cadillac Fairview",
  "Caisse des Depots",
  "CalPERS",
  "CalSTRS",
  "Camden Property Trust",
  "Cammeby's",
  "CapitaLand",
  "CAPREIT",
  "Carlyle Group",
  "Castellum AB",
  "Catella",
  "CBL",
  "CBRE Global Investors",
  "CCCG Real Estate",
  "CDL",
  "CDPQ",
  "Cerberus",
  "CFLD",
  "Charoen Pokphand Group",
  "Charter Hall Group",
  "China Aoyuan Properties",
  "China Cinda AM",
  "China Communications Const",
  "China Gezhouba Group Co",
  "China Intime Inv Co",
  "China Life",
  "China Merchants Group",
  "China Merchants Prop Dev",
  "China Metallurgical",
  "China Minmetals Corp",
  "China Oceanwide Holdings",
  "China Overseas L&I",
  "China Poly Group",
  "China Railway Construction",
  "China Railway Group",
  "China Resources",
  "China Resources Land",
  "China SCE Prop Holdings",
  "China State Engineering",
  "China Vanke",
  "Chinachem Group",
  "Choice Properties",
  "CIC",
  "CIFI Group",
  "CIM Group",
  "CITIC Group",
  "Citigroup",
  "City of New York",
  "CK Asset Holdings",
  "Clarion Partners",
  "CN Huafa Group",
  "CNP Assurances",
  "Colony Capital",
  "Columbia Property Trust",
  "Commerz Real",
  "CommonWealth Partners",
  "Corestate Capital",
  "Cortland",
  "Costco",
  "Country Garden",
  "Covivio",
  "CPP Investment Board",
  "Credit Agricole",
  "Credit Suisse",
  "Cromwell Property Group",
  "Crow Holdings",
  "Crown Acquisitions",
  "CTBC Financial",
  "CWS Capital Partners",
  "Dahua Group",
  "Dai-Ichi Life Holdings",
  "Daiwa House",
  "Daiwa House REIT",
  "Dalian Wanda Group",
  "DekaBank",
  "Delek Group",
  "Deutsche Wohnen SE",
  "DEXUS",
  "DIC Asset AG",
  "Digital Realty",
  "DivcoWest",
  "Diversified Heatlhcare Trust",
  "Douglas Emmett Realty",
  "DRA Advisors",
  "DTZ Investors",
  "Dubai World",
  "Duke Realty",
  "Durst Organization",
  "DWS Group",
  "DWS Group Americas",
  "Eaton Vance RE",
  "ECE",
  "Elvas Empreendimentos",
  "Emaar Properties",
  "Emperor Group",
  "Employees Provident Fund",
  "Equinix (REIT)",
  "Equity Residential",
  "ESR Cayman",
  "Essex Property Trust",
  "Excellence Group",
  "Exeter",
  "Extell",
  "Extended Stay America",
  "Extra Space Storage",
  "Facebook",
  "Fairfield Residential",
  "Fantasia Holdings",
  "Far East Organization",
  "Farallon Cap Prtnrs",
  "Fastighets AB Balder",
  "Federal Realty",
  "Federated Hermes",
  "Fibra Uno",
  "Financial Street Holding Co",
  "First Capital Realty",
  "Fortress",
  "Fosun International",
  "FPA Multifamily",
  "Frasers Property",
  "Fubon Financial",
  "Future Holdings",
  "Galaxy Holding Group",
  "Gaw Capital",
  "Gecina",
  "Gemdale Corp",
  "Genepierre",
  "Generali Group",
  "GIC",
  "GID",
  "GLL RE Partners",
  "GLP",
  "Goldbond Group",
  "Goldman Sachs",
  "Goodman Group",
  "GPT",
  "GrandJoy Group",
  "Greenland Group",
  "Greentown China",
  "Greystar",
  "Grosvenor",
  "Grupo BBVA",
  "Guangzhou Metro Corp",
  "Guardian Life Insurance",
  "GuocoLand",
  "H&R REIT",
  "Hammerson",
  "Hana Financial Group",
  "Hang Lung Prop Ltd",
  "Hansainvest",
  "Harbor Group International",
  "Harrison Street RE Cap",
  "Healthpeak Properties Inc",
  "Heimstaden",
  "Heitman",
  "Henderson Land Dev",
  "Highgate Holdings",
  "Hines",
  "HKMA",
  "HNA Group",
  "Hongkong Land",
  "Hopewell Holdings",
  "Hopson Development Hldg",
  "Host Hotels & Resorts",
  "Howard Hughes",
  "HSBC",
  "HTA",
  "Hudson Pacific Props",
  "Hulic",
  "ICD",
  "IGIS",
  "IKEA Fastigheter AB",
  "ING Group",
  "Inland RE Group",
  "Intercontinental RE",
  "Invesco Real Estate",
  "Irvine Co",
  "ISPT",
  "Jamestown",
  "Japan Post",
  "Jay Paul Co",
  "JBG Smith",
  "Jinke Property Group",
  "JMF REIT",
  "JP Morgan",
  "JR East",
  "JRE REIT",
  "K. Wah International",
  "Kaisa Group Holdings",
  "Kajima Corporation",
  "Kayne Anderson",
  "KB Financial Group",
  "Kennedy Wilson",
  "Keppel Corporation",
  "Keppel REIT",
  "Kerry Properties",
  "Kilroy Realty Corp",
  "Kimco",
  "KingSett Capital",
  "KKR",
  "Klepierre",
  "KORAMCO",
  "Kushner Companies",
  "Kuwait Investment Authority",
  "KWG Property Holding",
  "La Francaise",
  "Landsec",
  "LaSalle",
  "LBA Realty",
  "LEG Immobilien",
  "Legal & General",
  "Lehman Bros Holdings",
  "Lend Lease",
  "Lennar Corporation",
  "Lianfa Group",
  "Lincoln Property Co",
  "Link REIT",
  "Logan Property",
  "Lone Star",
  "Longfor Properties",
  "M&G Real Estate",
  "M7 Real Estate",
  "MAA REIT",
  "Macerich",
  "Macquarie Group",
  "Madison International",
  "Manulife Financial",
  "Mapletree Investments",
  "Mapletree Logistics Trust",
  "Mastern",
  "MBK Partners",
  "MERLIN Properties",
  "MetLife",
  "MGM Growth Properties",
  "MGM Resorts International",
  "Midea Grp",
  "Ministry of Finance Malaysia",
  "Mirae Asset",
  "Mirvac Group",
  "Mitsubishi Estate",
  "Mitsui & Co",
  "Mitsui Fudosan",
  "Mizuho Financial Group",
  "Morgan Properties",
  "Morgan Stanley",
  "Mori Building",
  "Mori Trust",
  "Nan Fung Group",
  "NBIM",
  "New Hope Group",
  "New World Development",
  "New York Life",
  "NH Invt & Securities",
  "NIAM",
  "Nippon Building Fund",
  "Nippon Prologis REIT",
  "Nomura Master Fund",
  "Nomura RE Hldgs",
  "Northwood Investors",
  "NPS",
  "NTT Urban Development",
  "Nuveen Real Estate",
  "NYSCRF",
  "Oaktree",
  "O'Connor Cap Prtnrs",
  "OCT Group",
  "Olayan Group",
  "Omega Healthcare",
  "OMERS",
  "ORIX REIT",
  "Panattoni Development",
  "Paramount Group (REIT)",
  "Park Hotels & Resorts",
  "Partners Group",
  "Patrizia",
  "PCCP (Pacific Coast)",
  "PFA - Mere til Dig",
  "PGIM Real Estate",
  "Ping An Insurance",
  "Power China",
  "PowerChina RE",
  "Powerlong RE",
  "Primonial REIM",
  "Principal Financial",
  "Prologis",
  "Prometheus RE",
  "PSP Investments",
  "QIA",
  "Qianhai Authority",
  "QIC",
  "Quantum Immobilien AG",
  "R&F Properties",
  "Radiance Group",
  "Realty Income Corp",
  "Red Star Macalline Grp",
  "Red Sun",
  "Redevco",
  "Regency Centers",
  "Related Companies",
  "RFR Realty",
  "RioCan REIT",
  "RiseSun RE",
  "Rivoli Avenir Patrimoine SCPI Amundi",
  "Road King Infrastructure",
  "Rockpoint Group",
  "Rockwood Capital",
  "Rongan Property",
  "Rongqiao Group",
  "Rongxin Group (Ronshine China)",
  "Rudin Mgmt Co",
  "RXR Realty",
  "Samsung Life",
  "Santander",
  "Savills IM",
  "SBB i Norden",
  "Scentre Group",
  "Schroders",
  "SCPI Edissimmo",
  "SCPI Primopierre",
  "SCPI Primovie",
  "SEGRO",
  "Sekisui House",
  "Shandong Luneng Grp",
  "Shanghai Const Grp",
  "Shanghai Lujiazui Finance",
  "Shanghai Municipal",
  "Shangri-La",
  "Shimao Group",
  "Shorenstein",
  "Shui On Land Limited",
  "Shun Tak",
  "Signa Group",
  "Silverstein Properties",
  "Simon Property Group",
  "Sino Land",
  "Sinochem Grp Corp",
  "Sino-Ocean Land",
  "SITE Centers",
  "SL Green",
  "SM Prime",
  "SmartCentres REIT",
  "Societe Generale",
  "Soho China",
  "Sonae Sierra",
  "Starlight Investments",
  "Starwood Capital",
  "Stellar Management",
  "Stockbridge",
  "Stockland",
  "STRS Ohio",
  "Sumitomo Corp",
  "Sumitomo Realty & Dev",
  "Sun Communities Inc",
  "Sun Hung Kai",
  "Sunac China",
  "Sunshine 100",
  "Suntec REIT",
  "Swire Properties",
  "Swiss Life AM",
  "Swiss Prime Site",
  "TA Realty",
  "Taisei Corp",
  "Tata Group",
  "Temasek",
  "TF Cornerstone",
  "Thaihot Group",
  "The Feil Organization",
  "Thor Equities",
  "TIAA",
  "Tianjin Real Estate",
  "Times China",
  "Tishman Speyer",
  "Tokyo Tatemono",
  "Tokyu Corp",
  "Tokyu Fudosan Holdings",
  "TPG Real Estate",
  "Trammell Crow Co (CBRE)",
  "Tristan Capital Partners",
  "Trump Organization",
  "Turnberry Associates",
  "UBS",
  "UDR",
  "Unibail-Rodamco-Westfield",
  "UniCredit",
  "Union Investment",
  "United Urban REIT",
  "Universal-Investment",
  "UOL Grp Ltd",
  "URA",
  "USAA Real Estate",
  "Ventas",
  "VEREIT",
  "VICI Properties",
  "Vicinity Centres",
  "Vonovia SE",
  "Vornado",
  "Walmart",
  "Walton Street Capital",
  "Warburg Pincus",
  "Warburg-HIH Invest",
  "Washington Prime Group",
  "Weidner Apt Homes",
  "Weingarten Realty",
  "Welltower",
  "Westbrook Partners",
  "Wharf Holdings",
  "Wheelock",
  "WP Carey REIT",
  "Xiamen C & D",
  "Xiamen ITG Group",
  "Xiangsheng Industrial Grp",
  "Yango Group",
  "Yanlord Land Group",
  "YMCI",
  "Youngor Group",
  "Yuexiu Property",
  "Yuzhou Properties",
  "Zhenro Group",
  "Zhongliang Group",
  "Zhongnan Group",
  "Zurich Financial",
];
