import { Grid, Button } from "@mui/material";
import Screen1 from "../assets/flow_hero8.png";
import RequestDemo from "./SignUp";
import ReactGA from "react-ga";
// import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const Section1 = (props) => {
  const handleLoginClick = () => {
    ReactGA.event({ category: "Login", action: "Log in button clicked" });
  };

  return (
    <div className="App">
      <Grid container>
        <Grid item lg={6} style={{ textAlign: "left" }}>
          <Grid
            item
            sx={{
              fontFamily: "Comfortaa",
              fontWeight: "bold",
              textAlign: "left",
              lineHeight: { xs: "40px", sm: "80px" },
              letterSpacing: "1px",
              padding: { xs: "80px 16px 0px 0px", sm: "100px 0px 0px 0px" },
              fontSize: { xs: "2rem", sm: "3.2rem" },
            }}
          >
            {/* Ready for the future */}
            {/* Technology that makes life better */}
            {/* Technology for business that makes life better */}
            {/* Advanced analytics for institutional property investors */}
            {/* Portfolio optimisation for institutional property investors */}
            Transformative software and data analytics for businesses
          </Grid>

          <Grid
            item
            className="App-subheader"
            sx={{
              fontFamily: "Comfortaa",
              fontSize: { xs: "1rem", sm: "1.5rem" },
              paddingTop: "80px",
              paddingBottom: "64px",
              fontWeight: 400,
              letterSpacing: "1px",
              textAlign: "left",
            }}
          >
            Faster. Smarter. Simpler.
          </Grid>

          <a
            href="https://app-uat.proflow.ai/"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 24 }}
              onClick={handleLoginClick}
            >
              Log in
            </Button>
          </a>

          <RequestDemo handleOpenSnack={props.handleOpenSnack} />
        </Grid>
        <Grid
          item
          lg={6}
          sx={{
            paddingTop: 16,
            flexGrow: 1,
            padding: {
              xs: "48px 0px 24px 24px",
              sm: "48px 0px 24px 24px",
              md: "48px 0px 24px 24px",
              lg: "120px 0px 0px 24px",
            },
          }}
        >
          <img
            src={Screen1}
            alt="map"
            style={{
              position: "relative",
              width: "90%",
              imageRendering: "-webkit-optimize-contrast",
            }}
          />
        </Grid>
      </Grid>
      {/* test <div class="arrow bounce">
        <KeyboardDoubleArrowDownIcon style={{ fontSize: 60 }} />
      </div> */}
    </div>
  );
};

export default Section1;
