import * as React from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Grid } from "@mui/material";
import ResponsiveAppBar from "./components/AppBar";
import Section1 from "./components/Section1";
import Partners from "./components/Partners";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Footer from "./components/Footer";

// import Wave from "./components/Wave";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const theme = createTheme({
  palette: {
    primary: {
      light: "#ccc",
      main: "#fff",
      dark: "#ddd",
      contrastText: "#14173a",
    },
    secondary: {
      light: "#ff7961",
      main: "#1d204c",
      dark: "#ba000d",
      contrastText: "#ddd",
    },
  },
});

function App() {
  const [openSnack, setOpenSnack] = React.useState(false);

  const handleOpenSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <ResponsiveAppBar handleOpenSnack={handleOpenSnack} />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              sx={{
                padding: {
                  xs: "120px 16px 40px 0px",
                  lg: "120px 40px 40px 0px",
                  xl: "120px 40px 40px 80px",
                },
                width: "80vw",
                minHeight: "100vh",
              }}
            >
              <Section1 handleOpenSnack={handleOpenSnack} />
            </Grid>

            <Snackbar
              open={openSnack}
              autoHideDuration={4000}
              onClose={handleCloseSnack}
            >
              <Alert severity="success" sx={{ width: "100%" }}>
                Your request has been sent! We'll be in touch soon.
              </Alert>
            </Snackbar>
            {/* <Wave /> */}
            <Partners />
            <Grid item style={{ padding: 0, width: "100%" }}>
              <Section2 />
            </Grid>
            <Grid item style={{ padding: 0, width: "100%" }}>
              <Section3 />
            </Grid>
            <Grid item style={{ padding: 0, width: "100%" }}>
              <Footer />
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// function Snack()  {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// };
