import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import LogoPNG2 from "../assets/flow_logo2.png";
import RequestDemo from "./SignUp";
import ContactButton from "./ContactButton";
import ReactGA from "react-ga";
import AnchorLink from "react-anchor-link-smooth-scroll";

const ResponsiveAppBar = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLoginClick = () => {
    ReactGA.event({ category: "Login", action: "Log in button clicked" });
  };

  return (
    <AppBar
      position="fixed"
      elevation="0"
      style={{
        background: "linear-gradient(#14173a 90%, #14173a00)",
        // background: "#14173a",
        padding: "12px 0 24px 0",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: "flex" }}
          >
            <img
              src={LogoPNG2}
              alt="flow logo"
              style={{
                height: 40,
                imageRendering: "-webkit-optimize-contrast",
              }}
            />
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", sm: "flex", md: "none" },
              justifyContent: "right",
            }}
          >
            <IconButton
              size="large"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              style={{ color: "#fff" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", sm: "block", md: "none" },
              }}
            >
              <AnchorLink href="#service1" style={{ color: "#2a2a2a" }}>
                <MenuItem onClick={handleCloseNavMenu}>Services</MenuItem>
              </AnchorLink>
              <AnchorLink href="#product1" style={{ color: "#2a2a2a" }}>
                <MenuItem onClick={handleCloseNavMenu}>Products</MenuItem>
              </AnchorLink>
              <AnchorLink href="#about" style={{ color: "#2a2a2a" }}>
                <MenuItem onClick={handleCloseNavMenu}>About us</MenuItem>
              </AnchorLink>
              <MenuItem onClick={handleCloseNavMenu}>
                <ContactButton
                  handleOpenSnack={props.handleOpenSnack}
                  variant="text"
                />
              </MenuItem>
              <a
                href="https://app-uat.proflow.ai/"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#2a2a2a" }}
              >
                <div onClick={handleLoginClick}>
                  <MenuItem onClick={handleCloseNavMenu}>Log in</MenuItem>
                </div>
              </a>
              <MenuItem onClick={handleCloseNavMenu}>
                <RequestDemo
                  handleOpenSnack={props.handleOpenSnack}
                  variant="text"
                />
              </MenuItem>
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                justifyContent: "right",
              },
            }}
          >
            <AnchorLink href="#service1" style={{ color: "#fff" }}>
              <Button
                variant="text"
                color="primary"
                style={{ marginRight: 32 }}
              >
                Services
              </Button>
            </AnchorLink>

            <AnchorLink href="#product1" style={{ color: "#fff" }}>
              <Button
                variant="text"
                color="primary"
                style={{ marginRight: 32 }}
              >
                Products
              </Button>
            </AnchorLink>

            <AnchorLink href="#about" style={{ color: "#fff" }}>
              <Button
                variant="text"
                color="primary"
                style={{ marginRight: 32 }}
              >
                About us
              </Button>
            </AnchorLink>
            <ContactButton handleOpenSnack={props.handleOpenSnack} />

            <a
              href="https://app-uat.proflow.ai/"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: 32 }}
                onClick={handleLoginClick}
              >
                Log in
              </Button>
            </a>

            <RequestDemo handleOpenSnack={props.handleOpenSnack} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
