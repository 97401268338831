import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { companies } from "../data/companies";
import emailjs from "@emailjs/browser";
import ReactGA from "react-ga";
import PrivacyPolicy from "./PrivacyPolicy";

const ContactButton = (props) => {
  const [open, setOpen] = React.useState(false);
  const form = React.useRef();

  const handleClickOpen = () => {
    setOpen(true);
    ReactGA.event({
      category: "ButtonContact",
      action: "Contact button clicked",
    });
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_a7718mc",
        "template_oc7zafp",
        form.current,
        "user_tCR9DBNhmnJ9veRjY5hMr"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setOpen(false);
    props.handleOpenSnack();
    form.current.reset();
  };

  return (
    <>
      {props.variant === "text" ? (
        <div onClick={handleClickOpen} style={{ paddingRight: 54 }}>
          Contact
        </div>
      ) : (
        <Button
          variant="text"
          color="primary"
          onClick={handleClickOpen}
          style={{ marginRight: 32 }}
        >
          Contact
        </Button>
      )}

      <Dialog open={open} onClose={handleClose}>
        <form ref={form} onSubmit={sendEmail}>
          <DialogTitle>Contact us</DialogTitle>
          <DialogContent style={{ minHeight: "50vh" }}>
            <DialogContentText>
              We'd love to hear from you. Please fill out the form below and
              we'll reach out very soon.
            </DialogContentText>
            <TextField
              label="Name"
              type="name"
              name="name"
              fullWidth
              variant="filled"
              color="secondary"
              style={{ margin: "32px 0 16px 0" }}
            />
            <TextField
              required
              label="Email"
              type="email"
              name="email"
              fullWidth
              variant="filled"
              color="secondary"
              style={{ margin: "16px 0 16px 0" }}
            />
            <Autocomplete
              disablePortal
              autoSelect
              autoComplete
              options={companies}
              style={{ margin: "16px 0 16px 0" }}
              color="secondary"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Company"
                  variant="filled"
                  color="secondary"
                  name="company"
                />
              )}
            />
            <TextField
              label="Message"
              name="comments"
              fullWidth
              multiline
              rows={4}
              variant="filled"
              color="secondary"
              style={{ margin: "16px 0 16px 0" }}
            />
          </DialogContent>
          <DialogActions>
            <div
              style={{
                position: "absolute",
                left: "24px",
                fontFamily: "Roboto",
                color: "gray",
                fontSize: "0.9rem",
              }}
            >
              <PrivacyPolicy />
            </div>

            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              style={{ backgroundColor: "#14173a", color: "white" }}
            >
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ContactButton;
