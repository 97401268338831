import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import GA4React from "ga-4-react";
import ReactGA from "react-ga";

const ga4react = new GA4React("G-0PEPGMBGEK");
ga4react.initialize().then().catch();

ReactGA.initialize("UA-217035798-1");
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
