import { Grid } from "@mui/material";
import Map from "../assets/map_locations.png";

import RoomIcon from "@mui/icons-material/Room";

const Section3 = () => {
  return (
    <div>
      {/* <div
        style={{
          height: 100,
          // background: "linear-gradient(#ddd 60%, #14173a)",
          background: "#A0A4B1",

          width: "100%",
        }}
      ></div> */}
      <div
        className="App"
        style={{
          // height: 1000,
          background: "#14173a",
          // background: "linear-gradient(#14173a 80%, #fff)",
          width: "100%",
        }}
      >
        <section id="about" />
        <Grid container sx={{ paddingLeft: "15vw", paddingRight: "15vw" }}>
          <Grid item lg={6} style={{ textAlign: "left" }}>
            <Grid
              item
              sx={{
                fontFamily: "Comfortaa",
                fontWeight: 1200,
                textAlign: "left",
                padding: { xs: "90px 16px 0px 0px", sm: "100px 48px 0px 0px" },
                fontSize: { xs: "2rem", sm: "3rem" },
                lineHeight: 1.6,
              }}
            >
              About us
            </Grid>

            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                padding: { xs: "40px 16px 40px 0px", sm: "60px 48px 60px 0px" },
                fontSize: { xs: "0.8rem", sm: "1rem" },
                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              Flow is a technology company, specialised in data, process
              optimisation and commercial real estate. We combine a diverse
              range of knowledge and experience, creating a unique set of skills
              that sit in the intersection of three main areas:
            </Grid>

            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                fontSize: { xs: "0.8rem", sm: "1rem" },
                padding: { xs: "0px 16px 0px 48px", sm: "0px 48px 0px 48px" },
                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Cutting edge technology and software development
              <p />
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Data flow, data science and Artificial Intelligence
              <p />
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Deep commercial real estate expertise
            </Grid>

            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                padding: { xs: "40px 16px 0px 0px", sm: "60px 48px 00px 0px" },
                fontSize: { xs: "0.8rem", sm: "1rem" },
                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              Our team of software developers, data scientists and real estate
              experts are based in Australia and Austria. The scope of our
              products and services is global.
            </Grid>
          </Grid>
          <Grid
            item
            lg={6}
            sx={{
              paddingTop: 16,
              flexGrow: 1,
              padding: {
                xs: "48px 0px 24px 24px",
                sm: "48px 0px 24px 24px",
                md: "48px 0px 24px 24px",
                lg: "240px 0px 24px 24px",
              },
            }}
          >
            <img
              src={Map}
              alt="map"
              style={{
                position: "relative",
                width: "100%",
                imageRendering: "-webkit-optimize-contrast",
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Section3;
