import { Grid } from "@mui/material";
import Screen2 from "../assets/screen2.png";
import Screen3 from "../assets/screen3.png";
import Screen4 from "../assets/screen4.png";
import Screen5 from "../assets/screen5.png";
import Screen6 from "../assets/screen6.png";

import RoomIcon from "@mui/icons-material/Room";

const Section2 = () => {
  return (
    <div>
      {/* <div
        style={{
          height: 200,
          background: "linear-gradient(#14173a 30%, #fff)",
          width: "100%",
        }}
      ></div> */}
      <div
        className="App"
        style={{
          // background: "#fff",
          background: "linear-gradient(#14173a 0%, #A0A4B1)",
          width: "100%",
          paddingBottom: 100,
        }}
      >
        <Grid container sx={{ paddingLeft: "15vw", paddingRight: "15vw" }}>
          <Grid item lg={6} style={{ textAlign: "left" }}>
            <section id="service1" />
            <Grid
              item
              sx={{
                fontFamily: "Comfortaa",
                fontWeight: 1200,
                textAlign: "left",
                padding: { xs: "100px 16px 0px 0px", sm: "160px 48px 0px 0px" },
                fontSize: { xs: "2rem", sm: "3rem" },
                lineHeight: 1.6,
              }}
            >
              Software development
            </Grid>

            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                fontSize: { xs: "1rem", sm: "1.5rem" },
                padding: { xs: "40px 16px 40px 0px", sm: "60px 48px 60px 0px" },

                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              <b>Bespoke and customised enterprise software</b>
            </Grid>
            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                fontSize: { xs: "0.8rem", sm: "1rem" },
                padding: { xs: "0px 16px 0px 48px", sm: "0px 48px 0px 48px" },

                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Enterprise software development focused on process optimisation
              and streamlining
              <p />
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Cutting-edge technology applied to simplify processes and create
              robust data flow, to ultimately help organisations become truly
              data-driven
              <p />
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Customisation of flow's products as per specific requirements
            </Grid>
          </Grid>

          <Grid
            item
            lg={6}
            sx={{
              paddingTop: 16,
              flexGrow: 1,
              padding: {
                xs: "48px 0px 0px 0px",
                sm: "48px 0px 0px 0px",
                md: "48px 0px 0px 0px",
                lg: "180px 0px 0px 0px",
              },
            }}
          >
            <img
              src={Screen6}
              alt="map"
              style={{
                position: "relative",
                width: "90%",
                imageRendering: "-webkit-optimize-contrast",
              }}
            />
          </Grid>

          <Grid item lg={6} style={{ textAlign: "left" }}>
            <section id="service2" />
            <Grid
              item
              sx={{
                fontFamily: "Comfortaa",
                fontWeight: 1200,
                textAlign: "left",
                padding: { xs: "100px 16px 0px 0px", sm: "160px 48px 0px 0px" },
                fontSize: { xs: "2rem", sm: "3rem" },
                lineHeight: 1.6,
              }}
            >
              Data structuring
            </Grid>

            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                fontSize: { xs: "1rem", sm: "1.5rem" },
                padding: { xs: "40px 16px 40px 0px", sm: "60px 48px 60px 0px" },

                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              <b>Unify all relevant data from multiple sources</b>
            </Grid>
            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                fontSize: { xs: "0.8rem", sm: "1rem" },
                padding: { xs: "0px 16px 0px 48px", sm: "0px 48px 0px 48px" },

                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Structure in-house data from your corporate ecosystem of tools
              <p />
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Structure data generated through services received from vendors
              (valuations, consultancy, property management,...)
              <p />
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              View your data in the context of benchmark data, including
              financials from over 1,000 REITs worldwide, and econometric and
              demographic data at a granular level
            </Grid>
          </Grid>

          <Grid
            item
            lg={6}
            sx={{
              paddingTop: 16,
              flexGrow: 1,
              padding: {
                xs: "48px 0px 0px 0px",
                sm: "48px 0px 0px 0px",
                md: "48px 0px 0px 0px",
                lg: "180px 0px 0px 0px",
              },
            }}
          >
            <img
              src={Screen4}
              alt="map"
              style={{
                position: "relative",
                width: "90%",
                imageRendering: "-webkit-optimize-contrast",
              }}
            />
          </Grid>

          <Grid item lg={6} style={{ textAlign: "left" }}>
            <section id="service3" />
            <Grid
              item
              sx={{
                fontFamily: "Comfortaa",
                fontWeight: 1200,
                textAlign: "left",
                padding: { xs: "100px 16px 0px 0px", sm: "180px 48px 0px 0px" },
                fontSize: { xs: "2rem", sm: "3rem" },
                lineHeight: 1.6,
              }}
            >
              Advanced analytics
            </Grid>

            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                padding: { xs: "40px 16px 40px 0px", sm: "60px 48px 60px 0px" },
                fontSize: { xs: "1rem", sm: "1.5rem" },
                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              <b>Global visibility of every metric of your entire portfolio</b>
            </Grid>

            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                fontSize: { xs: "0.8rem", sm: "1rem" },
                padding: { xs: "0px 16px 0px 48px", sm: "0px 48px 0px 48px" },
                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Review detailed metric performance using any grouping criteria
              (fund, property type, location, manager, valuer…)
              <p />
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Generate actionable insights by grouping and drilling down into
              the detail of each number <p />
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Combine property data with relevant econometric and demographic
              data and preview the potential impact on specific assets and
              markets
            </Grid>
          </Grid>

          <Grid
            item
            lg={6}
            sx={{
              paddingTop: 16,
              flexGrow: 1,
              padding: {
                xs: "48px 0px 0px 0px",
                sm: "48px 0px 0px 0px",
                md: "48px 0px 0px 0px",
                lg: "180px 0px 0px 0px",
              },
            }}
          >
            <img
              src={Screen2}
              alt="map"
              style={{
                position: "relative",
                width: "90%",
                imageRendering: "-webkit-optimize-contrast",
              }}
            />
          </Grid>

          <Grid item lg={6} style={{ textAlign: "left" }}>
            <section id="product1" />
            <Grid
              item
              sx={{
                fontFamily: "Comfortaa",
                fontWeight: 1200,
                textAlign: "left",
                padding: { xs: "100px 16px 0px 0px", sm: "160px 48px 0px 0px" },
                fontSize: { xs: "2rem", sm: "3rem" },
                lineHeight: 1.6,
              }}
            >
              Portfolio optimisation
            </Grid>

            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                fontSize: { xs: "1rem", sm: "1.5rem" },
                padding: { xs: "40px 16px 40px 0px", sm: "60px 48px 60px 0px" },

                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              <b>
                AI-driven identification of each lever that can be used for
                value optimisation
              </b>
            </Grid>
            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                fontSize: { xs: "0.8rem", sm: "1rem" },
                padding: { xs: "0px 16px 0px 48px", sm: "0px 48px 0px 48px" },

                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Applying artificial intelligence, machine learning and predictive
              analytics
              <p />
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Powerful data science tools to optimize decisions, predict and
              forecast
              <p />
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Applied anomaly detection, deep neural networks and random forest
              algorithms
            </Grid>
          </Grid>

          <Grid
            item
            lg={6}
            sx={{
              paddingTop: 16,
              flexGrow: 1,
              padding: {
                xs: "48px 0px 0px 0px",
                sm: "48px 0px 0px 0px",
                md: "48px 0px 0px 0px",
                lg: "180px 0px 0px 0px",
              },
            }}
          >
            <img
              src={Screen3}
              alt="map"
              style={{
                position: "relative",
                width: "90%",
                imageRendering: "-webkit-optimize-contrast",
              }}
            />
          </Grid>

          <Grid item lg={6} style={{ textAlign: "left" }}>
            <section id="product2" />
            <Grid
              item
              sx={{
                fontFamily: "Comfortaa",
                fontWeight: 1200,
                textAlign: "left",
                padding: { xs: "100px 16px 0px 0px", sm: "160px 48px 0px 0px" },
                fontSize: { xs: "2rem", sm: "3rem" },
                lineHeight: 1.6,
              }}
            >
              Valuation marketplace
            </Grid>

            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                fontSize: { xs: "1rem", sm: "1.5rem" },
                padding: { xs: "40px 16px 40px 0px", sm: "60px 48px 60px 0px" },

                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              <b>
                Coordinate all your global valuation services from one place
              </b>
            </Grid>
            <Grid
              item
              className="App-subheader"
              sx={{
                fontFamily: "Comfortaa",
                fontSize: { xs: "0.8rem", sm: "1rem" },
                padding: { xs: "0px 16px 0px 48px", sm: "0px 48px 0px 48px" },

                fontWeight: 400,
                textAlign: "left",
                lineHeight: 1.6,
              }}
            >
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              With a global scope, coordinate your RFP processes in an efficient
              and transparent way.
              <p />
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Track valuation servicer bids and valuation progress.
              <p />
              <RoomIcon
                style={{
                  color: "#f44336",
                  position: "absolute",
                  marginLeft: "-48px",
                }}
              />
              Create ad-hoc portfolio analyses and ad-hoc portfolio valuation
              reports, no matter who your valuation provider is.
            </Grid>
          </Grid>

          <Grid
            item
            lg={6}
            sx={{
              paddingTop: 16,
              flexGrow: 1,
              padding: {
                xs: "48px 0px 0px 0px",
                sm: "48px 0px 0px 0px",
                md: "48px 0px 0px 0px",
                lg: "180px 0px 0px 0px",
              },
            }}
          >
            <img
              src={Screen5}
              alt="map"
              style={{
                position: "relative",
                width: "90%",
                imageRendering: "-webkit-optimize-contrast",
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Section2;
